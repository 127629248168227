<template>
  <div id="hangPackSustentaNeutro">
    <Header
      imgUrl="header-nutricionclinicaadulta.png"
      :classes="{ texto: 'align-middle', img: 'w-100' }"
      :texto="$t('productos.header_nca')"
    />

    <div class="container my-4">
      <div class="row">
        <div class="col-md-4 col-xs-12 pe-0">
          <img
            src="@/assets/images/productos/HangPack_SustentaNeutro.webp"
            class="img-fluid pb-2"
          />
          <div class="d-flex flex-column">
            <span class="mt-2">
              {{ $t("productos.presentacionPack", { g: 172 }) }}
            </span>
          </div>
        </div>
        <div class="col-md-8 col-xs-12 ps-0 pl-mobile">
          <div class="row">
            <p
              class="pt-3"
              v-html="$t('productos.hangPackSustentaNeutro.descripcion')"
            ></p>
          </div>

          <div class="row">
            <div class="col-md-8">
              <CaracteristicasNutricionales
                :columnas="1"
                :caracteristicas="caracteristicas"
              />

              <div class="row mt-3">
                <div
                  class="
                    col-md-5
                    d-flex
                    justify-content-around
                    align-items-left
                  "
                >
                  <div>{{ $t("productos.alergenos") }}</div>
                  <div>
                    <img
                      :src="getIcon('icono-leche.png')"
                      class="img-fluid alergenos"
                    />
                  </div>
                  <div>
                    <img
                      :src="getIcon('icono-soja.png')"
                      class="img-fluid alergenos"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 perfil d-flex flex-column justify-content-between">
              <img
                :src="getIcon('HangPack.webp')"
                class="img-fluid"
              />
              <router-link class="info p-4 mb-2" :to="{name: 'HangPack'}">{{$t('hangPack.conoce_los_beneficios')}}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("tablasNutricionales.titulo") }}</h5>
        <button
          class="btn btn-sm btn-azul"
          @click="toggleTablaNutricional = !toggleTablaNutricional"
        >
          {{ toggleTablaNutricional ? "+" : "-" }}
        </button>
      </div>
    </div>

    <div class="container" :class="{ oculto: toggleTablaNutricional }">
      <TableHangPackSustentaNeutro />
    </div>

    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("productos.modoPreparacion.titulo") }}</h5>
      </div>
    </div>

    <div class="container">
      <ModoPreparacionHangPack
        :texto="$t('productos.hangPackSustentaNeutro.textoPreparacion')"
      />
    </div>

    <div class="container-fluid ntr-collapse bg-azul py-3 my-2">
      <div class="container">
        <h5>{{ $t("productos.otrosProductos") }}</h5>
      </div>
    </div>

    <CardsRecomendados
      :productos="[
        'sustenta-semielemental',
        'hang-pack',
        'sustenta-junior-sin-lactosa',
        'sustenta-neutro',
      ]"
    />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import CaracteristicasNutricionales from "@/components/CaracteristicasNutricionales.vue";
import es from "@/locales/es.json";
import en from "@/locales/en.json";
import TableHangPackSustentaNeutro from "@/components/tablas/TableHangPackSustentaNeutro.vue";
import ModoPreparacionHangPack from "@/components/ModoPreparacionHangPack.vue";
import CardsRecomendados from "@/components/CardsRecomendados.vue";

export default {
  name: "HangPackSustentaNeutro",
  components: {
    Header,
    CaracteristicasNutricionales,
    TableHangPackSustentaNeutro,
    ModoPreparacionHangPack,
    CardsRecomendados,
  },
  data() {
    return {
      toggleTablaNutricional: true,
      caracteristicas: {
        en: en.productos.hangPackSustentaNeutro.caracteristicas,
        es: es.productos.hangPackSustentaNeutro.caracteristicas,
      },
    };
  },
  methods: {
    getIcon(fileName) {
      return require("../../assets/images/icons/" +
        this.$i18n.locale +
        "/" +
        fileName);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>